import { render, staticRenderFns } from "./Credits.vue?vue&type=template&id=5ec306c5&scoped=true&"
import script from "./Credits.vue?vue&type=script&lang=ts&"
export * from "./Credits.vue?vue&type=script&lang=ts&"
import style0 from "./Credits.scss?vue&type=style&index=0&id=5ec306c5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ec306c5",
  null
  
)

export default component.exports